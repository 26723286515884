import axios, { AxiosHeaders } from 'axios';
import { accessTokenItemName, refreshTokenItemName } from 'src/config';

export const axiosApiInstance = axios.create();
const refreshTokenUrl = `${process.env.REACT_APP_REPOSIT_API_URL}/v2/auth/refresh/`;

const refreshAccessToken = (refresh_token: string) => {
  const access_token = axiosApiInstance
    .post(refreshTokenUrl, {
      refresh_token: refresh_token,
    })
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      return data.access_token;
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
  return access_token;
};

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem(accessTokenItemName);
    if (access_token) {
      config.headers = new AxiosHeaders({
        'Content-Type': 'application/json',
        'Reposit-Auth': 'Stateless',
        'access-control-allow-methods': '*',
        Authorization: `Bearer ${access_token}`,
      });

      return config;
    }
    config.headers = new AxiosHeaders({
      ...config.headers,
      'Content-Type': 'application/json',
      'Reposit-Auth': 'Stateless',
      'access-control-allow-methods': '*',
    });
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

/**
 * Use axios interceptor for token refresh
 * if response status is 401 refresh token and the requst is not retried yet
 * then refresh token and retry the request
 * else retun error
 */

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log('axios.error', error);
    const originalRequest = error.config;

    const refresh_token = localStorage.getItem(refreshTokenItemName);

    if (
      originalRequest.url !== refreshTokenUrl &&
      error.response?.status === 401 &&
      refreshTokenUrl &&
      !originalRequest._retry &&
      refresh_token
    ) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken(refresh_token);
      if (access_token) {
        localStorage.setItem(accessTokenItemName, access_token);
        return axiosApiInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);
