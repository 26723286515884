import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useRouteError } from 'react-router-dom';
import './ErrorPage.scss';
interface IErrorProps {}

const ErrorPage: React.FC<IErrorProps> = ({}) => {
  const error: any = useRouteError();
  const navigate = useNavigate();
  console.error(error);
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message || 'Unknow Error'}</i>
      </p>
      <Button onClick={() => navigate(-1)}>Go back</Button>
    </div>
  );
};

export default ErrorPage;
