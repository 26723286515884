import List from 'rc-virtual-list';
import type { FC} from 'react';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { MdRefresh } from 'react-icons/md';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { selectAuth } from 'src/redux/auth';
import { useAppSelector } from 'src/redux/hooks';
import variables from 'src/theme/_variables.scss';
import { fetchMessages, selectMessages } from '../../redux/messages';

import Message from 'src/components/Message/Message';
import './Messages.scss';
interface MessagesProps {}

const Messages: FC<MessagesProps> = () => {
  const auth = useAppSelector(selectAuth);
  const messages = useAppSelector(selectMessages);
  const dispatch = useDispatch();
  const [messageListHeight, setMessageListHeight] = useState<number>(100);
  const messageListRef = useRef<HTMLDivElement>(null);

  const resizeMessagesList = () => {
    if (messageListRef?.current?.clientHeight) {
      setMessageListHeight(messageListRef.current.clientHeight);
    }
  };

  useEffect(() => {
    resizeMessagesList();
    window.addEventListener('resize', resizeMessagesList);

    return () => {
      window.removeEventListener('resize', resizeMessagesList);
    };
  }, []);
  useEffect(() => {
    dispatch(fetchMessages(auth.userKey!));
  }, []);

  const messageRefresh = () => {
    if (!messages.loading) {
      dispatch(fetchMessages(auth.userKey!));
    }
  };

  if (messages.loading && messages.data.length == 0) {
    return (
      <div className="Messages" ref={messageListRef}>
        <div className="spinner">
          <Spinner variant="primary" />
        </div>
      </div>
    );
  }
  if (messages.error && messages.data.length == 0) {
    return (
      <div className="Messages" ref={messageListRef}>
        <MdRefresh />
        <p>Sorry an error occurred when fetching messages,Please try again </p>
      </div>
    );
  }
  if (messages.data.length == 0) {
    return (
      <div className="Messages" ref={messageListRef}>
        <MdRefresh />
        <p>You havent received any message </p>
      </div>
    );
  }

  return (
    <div className="Messages" ref={messageListRef}>
      <IconContext.Provider
        value={{
          color: variables.primary,
          className: messages.loading ? 'message-refresh-icon-loading' : 'message-refresh-icon',
        }}
      >
        <MdRefresh onClick={() => messageRefresh()} />
      </IconContext.Provider>

      <List
        className="message-list"
        data={messages.data}
        height={messageListHeight}
        itemHeight={90}
        itemKey="id"
      >
        {(item: any) => (
          <Message
            type={item.type}
            title={item.title}
            body={item.body}
            created_at={item.created_at}
          />
        )}
      </List>
    </div>
  );
};

export default Messages;
