import { useEffect, useState } from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import './AlertManager.scss';

type AlertVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

interface IAlertState {
  variant?: AlertVariant;
  timeout?: number | false;
  header?: string;
  content?: string;
  show: boolean;
}

const AlertManager = () => {
  const defaultState: IAlertState = {
    variant: 'primary',
    timeout: 2000,
    header: undefined,
    content: undefined,
    show: false,
  };
  const [state, setState] = useState<IAlertState>(defaultState);

  const handleAlert = (event: Event) => {
    // @ts-ignore
    setState({ ...event.detail, show: true });
    // @ts-ignore
    if (event.detail.timeout != false) {
      setTimeout(() => {
        setState(defaultState);
        // @ts-ignore
      }, event.detail.timeout);
    }
  };

  useEffect(() => {
    window.addEventListener('alert', handleAlert);

    return () => {
      window.removeEventListener('alert', handleAlert);
    };
  });

  return (
    <BootstrapAlert
      className="alert_manager"
      show={state.show}
      key={state.variant}
      variant={state.variant}
    >
      {state.header && <BootstrapAlert.Heading>{state.header}</BootstrapAlert.Heading>}
      {state.content}
    </BootstrapAlert>
  );
};
AlertManager.defaultProps = {
  variant: 'primary',
  timeout: 2000,
  header: undefined,
  content: undefined,
};

export default AlertManager;

interface IAlertProps {
  variant?: AlertVariant;
  timeout?: number | false;
  header?: string;
  content?: string;
}
export class Alert {
  static show = (params: IAlertProps) => {
    const customEvent = new CustomEvent('alert', {
      detail: {
        variant: 'primary',
        timeout: 2000,
        header: undefined,
        content: undefined,
        ...params,
      },
    });
    dispatchEvent(customEvent);
  };
}
