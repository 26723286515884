import { createAction, createReducer } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface MessagesState {
  loading: boolean;
  error: boolean;
  data: [];
}

export const fetchSuccess = createAction('messages/fetchSuccess');
export const fetchError = createAction('messages/fetchError');
export const fetchMessages = createAction('messages/fetch', function prepare(userKey: string) {
  return {
    payload: {},
    meta: {
      method: 'GET',
      endpoint: `/v2/deployments/${userKey}/messages`,
      success: fetchSuccess,
      error: fetchError,
    },
  };
});

const initialState = {
  data: [],
  loading: false,
  error: false,
} as MessagesState;

export const messagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchMessages, (state) => {
      state.loading = true;
      state.error = false;
    })
    .addCase(fetchSuccess, (state, action: any) => {
      state.data = action.payload.data.reverse();
      state.loading = false;
    })
    .addCase(fetchError, (state) => {
      state.loading = false;
      state.error = true;
    });
});

// Other code such as selectors can use the imported `RootState` type
export const selectMessages = (state: RootState) => state.messages;
