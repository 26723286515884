import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { RouterProvider } from 'react-router-dom';
import { loginError, reFetchAccount, selectAuth } from 'src/redux/auth';

import './App.scss';
import AlertManager from './components/AlertManager/AlertManager';
import { refreshTokenItemName } from './config';
import { useAppSelector } from './redux/hooks';
import { router } from './routes/root';

interface IAppProps {}

const App: React.FC<IAppProps> = ({}) => {
  const auth = useAppSelector(selectAuth);
  const dispatch = useDispatch();
  const { status } = auth;
  useEffect(() => {
    const refresh_token = localStorage.getItem(refreshTokenItemName);
    if (!!refresh_token) {
      dispatch(reFetchAccount());
    } else {
      dispatch(loginError());
    }
  }, []);

  return (
    <>
      <AlertManager />
      <RouterProvider router={router(status)} />
    </>
  );
};
export default App;
