import { combineReducers } from '@reduxjs/toolkit';
import { activitiesReducer } from './activities';
import { authReducer } from './auth';
import { messagesReducer } from './messages';
import { settlementReducer } from './settlement';

export const rootReducers = combineReducers({
  auth: authReducer,
  settlement: settlementReducer,
  messages: messagesReducer,
  activities: activitiesReducer,
});
