import type { MiddlewareAPI } from '@reduxjs/toolkit';
import type { Dispatch, Middleware } from 'redux';
import { Alert } from 'src/components/AlertManager/AlertManager';
import { axiosApiInstance } from './axios';

export const fetchMiddleware: Middleware<Dispatch> =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    next(action);
    if (!action.meta || !action.meta.endpoint) {
      // No URL to invoke so just next the action.
      return;
    }

    axiosApiInstance({
      method: action.meta.method,
      url: `${process.env.REACT_APP_REPOSIT_API_URL}${action.meta.endpoint}`,
      data: action.meta.body,
    })
      .then((response) => {
        return response.data;
      })
      .then((json) => {
        if (action.meta.success) {
          const successResult = action.meta.success({ ...json, ...action.payload }, action.payload);
          if (successResult && successResult.type) {
            dispatch(successResult);
          }
        }
      })
      .catch((error) => {
        console.warn('error', error);
        Alert.show({ variant: 'warning', content: error.message });
        const errorResult = action.meta.error(error.message, action.payload);
        if (errorResult && errorResult.type) {
          next(errorResult);
        }
      });
  };
