import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useNavigate } from 'react-router-dom';
import logo from 'src/assets/img/logo.svg';
import { login, selectAuth } from 'src/redux/auth';
import { useAppSelector } from 'src/redux/hooks';

import './Login.scss';
interface ILoginProps {}

const Login: React.FC<ILoginProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);

  useEffect(() => {
    if (auth.status == 'LOGGED_IN') {
      navigate('/dashboard/activity', { replace: true });
    }
  }, [auth]);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    const login_params: API.LoginParams = {
      // @ts-ignore
      username: event.target[0].value,
      // @ts-ignore
      password: event.target[1].value,
    };
    dispatch(login(login_params));
  };

  return (
    <div className="Login">
      <Form className="login-form" onSubmit={handleSubmit}>
        <img className="sidebar-logo" src={logo} />
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Email or Username</Form.Label>
          <Form.Control
            className="login-form-control"
            required
            type="text"
            placeholder="Enter email or username"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            className="login-form-control"
            required
            type="password"
            placeholder="Password"
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={auth.status == 'LOGGING'}>
          {auth.status == 'LOGGING' && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          )}
          {auth.status == 'LOGGING' ? ' Signing in... ' : 'Sign in to reposit'}
        </Button>

        <Form.Text className="text-muted" onClick={() => navigate('/login/password')}>
          Forgot your password?
        </Form.Text>
      </Form>
    </div>
  );
};

export default Login;
