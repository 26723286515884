import type { FC } from 'react';

import moment from 'moment-timezone';
import GraphArea from 'src/components/GraphArea/GraphArea';
import { selectActivities } from 'src/redux/activities';
import { useAppSelector } from 'src/redux/hooks';
import './ActicityGraph.scss';
interface ActivityGraphProps {
  startTimestamp: number;
  endTimestamp: number;
  data: any;
  predictionData: any;
  type: 'meter' | 'house' | 'solar' | 'battery';
  isToday: boolean;
  tariff: number;
}

const TYPE_MAP = {
  meter: {
    state: 'Grid Import',
    value: 'grid_positive',
    unit: 'kW',
    stroke: 'rgb(70, 152, 203)',
    fill: 'rgba(70, 152, 203,0.2)',
  },

  house: {
    state: 'House Usage',
    value: 'house',
    unit: 'kW',
    stroke: 'rgb(81, 199, 109)',
    fill: 'rgba(81, 199, 109,0.2)',
  },
  solar: {
    state: 'Solar Generation',
    value: 'solar',
    unit: 'kW',
    stroke: 'rgb(254, 158, 67)',
    fill: 'rgba(254, 158, 67,0.2)',
  },
  battery: {
    state: 'Battery State of Charge',
    value: 'grid_positive',
    unit: '%',
    stroke: 'rgb(87, 196, 199)',
    fill: 'rgba(87, 196, 199,0.2)',
  },
};

const ActivityGraph: FC<ActivityGraphProps> = (props) => {
  const activities = useAppSelector(selectActivities);
  moment(props.startTimestamp * 1000);

  const stats_value = activities.realtime.day[TYPE_MAP[props.type].value]
    ? Math.abs(activities.realtime.day[TYPE_MAP[props.type].value].toFixed(2))
    : '';
  const grid_export = activities.realtime.day?.grid_negative
    ? Math.abs(activities.realtime.day.grid_negative.toFixed(2))
    : '';

  return (
    <div className="activity-graph-container">
      <div className="stats-container">
        <div className="stat">
          {props.type != 'battery' && (
            <div className={`value ${props.type}`}>
              {stats_value}
              <span className="value-symbol">kWh</span>
            </div>
          )}
          <div className={`label ${props.type}`}>{TYPE_MAP[props.type].state}</div>
        </div>
        {props.type == 'meter' && (
          <div className="stat">
            <div className="value">
              {grid_export}
              <span className="value-symbol">kWh</span>
            </div>
            <div className="label"> Grid Export</div>
          </div>
        )}
      </div>
      <GraphArea
        yLabelUnit={TYPE_MAP[props.type].unit}
        startTimestamp={props.startTimestamp}
        endTimestamp={props.endTimestamp}
        interval={1800}
        type={props.type}
        data={props.data}
        predictionData={props.predictionData}
        stroke={TYPE_MAP[props.type].stroke}
        fill={TYPE_MAP[props.type].fill}
        tariff={props.tariff}
      />
      <hr className="activity-hr" />
    </div>
  );
};
export default ActivityGraph;
