import type { FC } from 'react';
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryPie, VictoryTooltip } from 'victory';
import './CalendarDayContent.scss';

interface CalendarDayContentProps {
  data: { x: string; y: number }[];
  label?: number;
}

const CalendarDayContent: FC<CalendarDayContentProps> = (props) => (
  <div className="CalendarDayContent">
    <VictoryChart width={16} height={16}>
      <VictoryPie
        data={props.data}
        colorScale={['#45a9d3', 'lightgrey']}
        width={16}
        height={16}
        radius={7}
        innerRadius={6}
        labels={() => null}
        labelComponent={<VictoryTooltip />}
        standalone={false}
      />
      <VictoryAxis
        tickFormat={() => ''}
        style={{
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'transparent' },
          tickLabels: { fill: 'transparent' },
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 5, fill: '#3ca9d7' }}
        x={8}
        y={8}
        text={props.label?.toFixed(1)}
      />
    </VictoryChart>
  </div>
);

export default CalendarDayContent;
