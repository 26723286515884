import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { selectActivities, setSelectedTimeStamp } from 'src/redux/activities';
import { selectAuth } from 'src/redux/auth';
import { useAppSelector, useElementSize } from 'src/redux/hooks';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryVoronoiContainer } from 'victory';
import './GraphArea.scss';

const tooltipTime = (unixTimstamp: number, timezone: string) => {
  return moment(unixTimstamp * 1000)
    .tz(timezone)
    .format('hh:mm A');
};
interface FlyOutProps {
  timestamp: number;
  domain: { x: [number, number]; y: [number, number] };
  graphSize: { width: number; height: number } | null;
  stroke: string;
  data: any;
  yLabelUnit: string;
  type: 'meter' | 'house' | 'solar' | 'battery';
}

const FlyOut: React.FC<FlyOutProps> = (props) => {
  const { timestamp, domain, graphSize, data, stroke, yLabelUnit, type } = props;
  const auth = useAppSelector(selectAuth);
  const timezone = auth.timezone || moment.tz.guess();
  const [toolTipPosition, setToolTipPosition] = useState<{ x: number; y: number } | null>(null);

  const tooltipData = !!timestamp
    ? data.find((ele: { x: number; y: number }) => ele.x === timestamp)
    : undefined;

  useEffect(() => {
    if (!!tooltipData && !!graphSize) {
      const xRange = domain.x[1] - domain.x[0];
      const yRange = domain.y[1] - domain.y[0];
      const toolTipX = ((tooltipData.x - domain.x[0]) / xRange) * graphSize.width;
      const toolTipY = ((tooltipData.y - domain.y[0]) / yRange) * graphSize.height;
      setToolTipPosition({ x: toolTipX, y: toolTipY });
    }
  }, [timestamp]);

  if (!toolTipPosition) {
    return null;
  }
  return (
    <>
      <div
        className="graph-tooltip"
        style={
          // @ts-ignore
          toolTipPosition.x + 100 < graphSize?.width
            ? {
                left: toolTipPosition.x + 10,
                bottom: toolTipPosition.y,
              }
            : {
                left: toolTipPosition.x - 100,
                bottom: toolTipPosition.y,
              }
        }
      >
        <div className="graph-tooltip-data" style={{ color: stroke }}>{`${tooltipData.y.toFixed(
          2,
        )} ${yLabelUnit}`}</div>
        {type === 'meter' && (
          <div className="graph-tooltip-tag">{tooltipData.y > 0 ? 'IMPORTED' : 'EXPORTED'}</div>
        )}
        <div className="graph-tooltip-time">{tooltipTime(timestamp, timezone)}</div>
      </div>
      <div
        className="graph-tooltip-line"
        style={{
          left: toolTipPosition.x - 1,
        }}
      />
      <div
        className="graph-tooltip-circle"
        style={{
          left: toolTipPosition.x - 5,
          bottom: toolTipPosition.y,
          backgroundColor: stroke,
        }}
      />
    </>
  );
};

interface GraphAreaProps {
  yLabelUnit: string;
  startTimestamp: number;
  endTimestamp: number;
  interval: number;
  type: 'meter' | 'house' | 'solar' | 'battery';
  data: any;
  predictionData?: any;
  stroke: any;
  fill: any;
  tariff: number | undefined;
}

const GraphArea: React.FC<GraphAreaProps> = (props) => {
  const dispatch = useDispatch();
  const activities = useAppSelector(selectActivities);

  const [graphSize, graphRef] = useElementSize();

  const pixelsToY = (min: number, max: number, pixels: number) => {
    const unitsPerPixel = (max - min) / 160;
    return unitsPerPixel * pixels;
  };

  const getDomain = (): {
    domain: { x: [number, number]; y: [number, number] };
    xTicks: number[];
    yTick: number[];
    hasYVal: boolean;
  } => {
    const tickSpacing = props.interval;
    const startTimestamp = props.startTimestamp;
    const endTimestamp = props.endTimestamp;

    let timestamp = startTimestamp;
    const xTicks = [];
    while (timestamp <= endTimestamp) {
      xTicks.push(timestamp);
      timestamp += tickSpacing;
    }

    let min, max;
    if (props?.data) {
      if (!!props?.predictionData) {
        min = Math.min(props.data.min, props.predictionData.min);
        max = Math.max(props.data.max, props.predictionData.max);
      } else {
        min = props.data.min;
        max = props.data.max;
      }
    } else {
      min = 0;
      max = 1;
    }

    if (props.yLabelUnit === '%') {
      min = -1;
    } else {
      // min = min === 0 ? -1 : min - 1 - Math.abs(min * PADDING_PCT);
      min = min === 0 ? -1 : min - pixelsToY(min, max, 20);
    }

    const hasYVal = max !== 0;
    const yTick = max === 0 ? 1 : max;

    max = max === 0 ? 1 : max + pixelsToY(min, max, 20); // Ensure there is always enough room for the y-axis label

    const domain = {
      x: [startTimestamp, endTimestamp],
      y: [min, +max],
    } as { x: [number, number]; y: [number, number] };

    return {
      domain,
      xTicks,
      yTick,
      hasYVal,
    };
  };
  // @ts-ignore
  const { data, predictionData, yLabelUnit } = props;

  const width = graphSize?.width;
  const height = graphSize?.height;

  if (data.data.length == 0 && data.loading) {
    return (
      <div className="graph-widget">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  if (data.data && data.data?.length === 0) {
    return (
      <div className="graph-widget">
        <p>No data to dispaly</p>
      </div>
    );
  }

  const padding = { top: 0, right: 0, bottom: 0, left: 0 };
  const { domain, xTicks } = getDomain();
  const selectedTimestamp = activities.selectedTimestamp;

  return (
    <div
      className="graph-widget"
      ref={graphRef}
      onMouseLeave={() => dispatch(setSelectedTimeStamp(null))}
    >
      <div className="graph-container">
        {!!selectedTimestamp && (
          <FlyOut
            timestamp={selectedTimestamp}
            domain={domain}
            stroke={props.stroke}
            data={data.data.concat(predictionData?.data ? predictionData.data : [])}
            graphSize={graphSize}
            yLabelUnit={yLabelUnit}
            type={props.type}
          />
        )}
        {width && (
          <VictoryChart
            width={width}
            height={height}
            padding={padding}
            style={{
              parent: {
                backgroundColor: 'rgba(230, 236, 247, 0.5)',
                stroke: 'none',
              },
            }}
            containerComponent={
              <VictoryVoronoiContainer
                onActivated={(value) => {
                  if (value!) {
                    dispatch(setSelectedTimeStamp(value[0]._x));
                  }
                }}
              />
            }
          >
            <VictoryAxis
              width={width}
              height={height}
              padding={padding}
              domain={domain}
              dependentAxis
              style={{
                axis: { stroke: 'transparent', strokeWidth: 3 },
                grid: {
                  stroke: 'white',
                  strokeWidth: 2,
                },
              }}
              orientation="right"
              tickValues={[0]}
              tickFormat={() => ''}
            />
            {/* x-axis white lines */}
            <VictoryAxis
              width={width}
              height={height}
              padding={padding}
              domain={domain}
              style={{
                axis: { stroke: 'none' },
                grid: { stroke: '#FFFFFF', strokeWidth: 1 },
              }}
              orientation="bottom"
              tickValues={xTicks}
              tickFormat={() => ''}
              standalone={false}
            />

            <VictoryArea
              width={width}
              height={height}
              padding={padding}
              domain={domain}
              data={data.data}
              interpolation="catmullRom"
              style={{
                data: {
                  stroke: props.stroke,
                  fill: props.fill,
                  strokeWidth: 2,
                },
              }}
              standalone={false}
            />

            {/* prediction data  */}
            {!!predictionData && predictionData.data.length > 0 && (
              <VictoryArea
                width={width}
                height={height}
                padding={padding}
                data={[data.data[data.data.length - 1]].concat(predictionData.data)}
                domain={domain}
                interpolation="natural"
                style={{
                  data: {
                    stroke: props.stroke,

                    fill: 'transparent',
                    strokeDasharray: '4, 4',
                    strokeWidth: 2,
                  },
                }}
                standalone={false}
              />
            )}
          </VictoryChart>
        )}

        <div className="graph-tariff-wrapper" style={{ backgroundColor: props.fill }}>
          <div className="graph-tariff">
            Base Rate - ${props.tariff ? props.tariff.toFixed(2) + '/kWh' : ' is unknown'}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GraphArea;
