import { createAction, createReducer } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface SettlementState {
  data: any;
}

export const fetchSuceess = createAction('settlement/fetchSuccess');
export const fetchError = createAction('settlement/fetchError');
export const fetchSettlement = createAction(
  'settlement/fetch',
  function prepare(data: API.SettlementParams) {
    const { userKey, endDate, startDate } = data;
    return {
      payload: { startDate: startDate },
      meta: {
        method: 'GET',
        endpoint: `/v2/deployments/${userKey}/settlement/daily?end=${endDate}&start=${startDate}&summary=true`,
        success: fetchSuceess,
        error: fetchError,
      },
    };
  },
);

const initialState = {
  data: {},
  activeStartDate: new Date(),
} as SettlementState;

export const settlementReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchSuceess, (state, action: any) => {
    state.data[`${action.payload.startDate}`] = action.payload.data;
  });
});

// Other code such as selectors can use the imported `RootState` type
export const selectSettlement = (state: RootState) => state.settlement;
