import { useCallback, useState } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useElementSize = (): [{ width: number; height: number } | null, any] => {
  const [rect, setRect] = useState(null);

  const ref = useCallback((node: any) => {
    if (!!node) {
      setRect(node.getBoundingClientRect());
      const resizeObserver = new ResizeObserver(() => {
        setRect(node.getBoundingClientRect());
      });
      if (!node) return;
      resizeObserver.observe(node);
    }
  }, []);
  return [rect, ref];
};
