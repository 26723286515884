// @ts-nocheck
import { createAction, createReducer } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import type { RootState } from './store';
interface ActicitiesState {
  day: {
    startTimestamp: number;
    endTimestamp: number;
    isToday: boolean;
  };
  meterP: any;
  houseP: any;
  solarP: any;
  consumption: any;
  battery: any;
  predictions: any;
  realtime: any;
  tariff: any;

  selectedTimestamp: number | null;
}

const DATA_INTERVAL = 300;
const PREDICTION_INTERVAL = 1800;

export function processData(data: any, adjustment: any) {
  const now = moment().unix();

  let dataFound = false;
  let min = null,
    max = null,
    latest = null;
  let totalPositive = 0,
    totalNegative = 0;
  const processedData = [];
  if (data) {
    for (const i in data) {
      // @ts-ignore
      if (i === data.length - 1) {
        // Skip the latest data point as this often dips because not all the data is in yet.
        continue;
      }
      const thisData = data[i];
      const timestamp = thisData[0];
      if (timestamp > now) {
        continue;
      }
      // Track if we only have nulls for the all of the data.
      if (thisData[1] !== null) {
        dataFound = true;
      }

      const dataPoint = Number(thisData[1]) || 0;
      const adjustedDataPoint = adjustment ? adjustment(dataPoint) : dataPoint;
      processedData.push({
        x: timestamp,
        y: adjustedDataPoint,
      });

      min = adjustedDataPoint < min || !min ? adjustedDataPoint : min;
      max = adjustedDataPoint > max || !max ? adjustedDataPoint : max;
      latest = adjustedDataPoint;

      if (adjustedDataPoint > 0) {
        totalPositive += adjustedDataPoint * (DATA_INTERVAL / 3600); // Normalise interval to kWh
      } else {
        totalNegative += Math.abs(adjustedDataPoint) * (DATA_INTERVAL / 3600); // Normalize interval to kWh
      }
    }
  }
  return {
    data: dataFound ? processedData : [], // If we only got nulls we should normalise this to 0 instead we should treat it as no data.
    min,
    max,
    totalPositive,
    totalNegative,
    latest,
  };
}

function processPrediction(
  data: any,
  predictionStartTimestamp: any,
  endTimestamp: any,
  adjustment: any,
) {
  const now = moment().unix();

  let min = null;
  let max = null;
  const processedData = [];
  if (data) {
    for (const i in data) {
      // @ts-ignore
      if (i === data.length - 1) {
        // Skip the latest data point as this often dips because not all the data is in yet.
        continue;
      }
      const thisData = data[i];
      // @ts-ignore
      const timestamp = predictionStartTimestamp + PREDICTION_INTERVAL * i;
      if (timestamp < now || timestamp > endTimestamp) {
        continue;
      }
      const dataPoint = thisData ? thisData : 0;
      const adjustedDataPoint = adjustment ? adjustment(dataPoint) : dataPoint;
      processedData.push({
        x: timestamp,
        y: adjustedDataPoint,
      });

      min = adjustedDataPoint < min || !min ? adjustedDataPoint : min;
      max = adjustedDataPoint > max || !max ? adjustedDataPoint : max;
    }
  }

  return {
    data: processedData,
    min: min,
    max: max,
  };
}
export const resetActivityDay = createAction('activities/resetActivityDay');
export const setActivityDay = createAction(
  'activities/setActivityDay',
  function prepare(params: { startTimestamp: number; endTimestamp: number; isToday: boolean }) {
    return {
      payload: params,
    };
  },
);

export const fetchMeterPSuceess = createAction('activities/fetchMeterPSuccess');
export const fetchMeterPError = createAction('activities/fetchMeterPError');
export const fetchMeterP = createAction(
  'activities/fetchMeterP',
  function prepare(timezone, userKey, startTimestamp, endTimestamp) {
    return {
      payload: {},
      meta: {
        method: 'GET',
        endpoint: `/v2/deployments/${userKey}/meter/historical/p?aggregation_base=client&delta_t=${DATA_INTERVAL}&end=${endTimestamp}&start=${startTimestamp}`,
        success: fetchMeterPSuceess,
        error: fetchMeterPError,
      },
    };
  },
);

// house
export const fetchHousePSuceess = createAction('activities/fetchHouseSuccess');
export const fetchHousePError = createAction('activities/fetchHouseError');
export const fetchHouseP = createAction(
  'activities/fetchHouse',
  function prepare(userKey, startTimestamp, endTimestamp) {
    return {
      payload: {},
      meta: {
        method: 'GET',
        endpoint: `/v2/deployments/${userKey}/house/historical?aggregation_base=client&delta_t=${DATA_INTERVAL}&end=${endTimestamp}&start=${startTimestamp}`,
        success: fetchHousePSuceess,
        error: fetchHousePError,
      },
    };
  },
);

// solar

export const fetchSolarPSuceess = createAction('activities/fetchSolarSuccess');
export const fetchSolarPError = createAction('activities/fetchSolarError');
export const fetchSolarP = createAction(
  'activities/fetchSolar',
  function prepare(userKey, startTimestamp, endTimestamp) {
    return {
      payload: {},
      meta: {
        method: 'GET',
        endpoint: `/v2/deployments/${userKey}/generation/historical/p?aggregation_base=client&delta_t=${DATA_INTERVAL}&end=${endTimestamp}&start=${startTimestamp}`,
        success: fetchSolarPSuceess,
        error: fetchSolarPError,
      },
    };
  },
);

// consumption

export const fetchConsumptionSuceess = createAction('activities/fetchConsumptionSuccess');
export const fetchConsumptionError = createAction('activities/fetchConsumptionError');
export const fetchConsumption = createAction(
  'activities/fetchConsumption',
  function prepare(userKey) {
    return {
      payload: {},
      meta: {
        method: 'GET',
        endpoint: `/app/selfconsumption?user_key=${userKey}&interval=day`,
        success: fetchConsumptionSuceess,
        error: fetchConsumptionError,
      },
    };
  },
);

// battery

export const fetchBatterySuceess = createAction('activities/fetchBatterySuccess');
export const fetchBatteryError = createAction('activities/fetchBatteryError');
export const fetchBattery = createAction(
  'activities/fetchBattery',
  function prepare(userKey, startTimestamp, endTimestamp) {
    return {
      payload: {},
      meta: {
        method: 'GET',
        endpoint: `/v2/deployments/${userKey}/battery/historical/soc?aggregation_base=client&delta_t=${DATA_INTERVAL}&end=${endTimestamp}&start=${startTimestamp}`,
        success: fetchBatterySuceess,
        error: fetchBatteryError,
      },
    };
  },
);

// prediction

export const fetchPredictionsSuceess = createAction('activities/fetchPredictionsSuccess');
export const fetchPredictionsError = createAction('activities/fetchPredictionsError');
export const fetchPredictions = createAction(
  'activities/fetchPredictions',
  function prepare(timezone, userKey, startTimestamp, endTimestamp) {
    return {
      payload: {
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
      },
      meta: {
        method: 'GET',
        endpoint: `/v2/deployments/${userKey}/prediction/all?delta_t=${PREDICTION_INTERVAL}
        &ts=${Math.floor(new Date().getTime() / 1000)}`,
        success: fetchPredictionsSuceess,
        error: fetchPredictionsError,
      },
    };
  },
);

// realtime

export const fetchRealTimeSuceess = createAction('activities/fetchRealTimeSuccess');
export const fetchRealTimeError = createAction('activities/fetchRealTimeError');
export const fetchRealTime = createAction(
  'activities/fetchRealTime',
  function prepare(userKey, interval, enableRealtime, date) {
    let endpoint;
    if (date) {
      endpoint = `/v2/deployments/${userKey}/summary/${interval}?date=${date}&enable_realtime=${enableRealtime}`;
    } else {
      endpoint = `/v2/deployments/${userKey}/summary/${interval}?enable_realtime=${enableRealtime}`;
    }
    return {
      payload: {},
      meta: {
        method: 'GET',
        endpoint: endpoint,
        success: fetchRealTimeSuceess,
        error: fetchRealTimeError,
      },
    };
  },
);
// tariff
export const fetchTariffSuceess = createAction('activities/fetchTariffSuceess');
export const fetchTariffError = createAction('activities/fetchTariffError');
export const fetchTariff = createAction('activities/fetchRealTime', function prepare(userKey) {
  return {
    payload: {},
    meta: {
      method: 'GET',
      endpoint: `/v2/deployments/${userKey}/tariff/tou`,
      success: fetchTariffSuceess,
      error: fetchTariffError,
    },
  };
});

export const setSelectedTimeStamp = createAction<number | null>('activities/setSelectedTimeStamp');

const dat_init = moment().startOf('day');
const startTimestamp = dat_init.unix();
const endTimestamp = dat_init.add(1, 'day').startOf('day').unix() - 1;
const initialState = {
  day: {
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
    isToday: true,
    // startTimestamp: 1670418000,
    // endTimestamp: 1670504399,
  },
  meterP: {
    data: [],
    loading: false,
    error: false,
  },
  houseP: {
    data: [],
    loading: false,
    error: false,
  },
  solarP: {
    data: [],
    loading: false,
    error: false,
  },
  consumption: {
    data: {},
    loading: false,
    error: false,
  },
  battery: {
    data: [],
    loading: false,
    error: false,
  },
  predictions: {
    data: {},
    loading: false,
    error: false,
  },
  realtime: {
    day: {},
    hour: {},
    now: {},
  },
  tariff: {},
  selectedTimestamp: null,
} as ActicitiesState;

export const activitiesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetActivityDay, () => {
      return { ...initialState };
    })
    .addCase(setActivityDay, (state, action) => {
      return { ...initialState, day: action.payload };
    })
    // meter
    .addCase(fetchMeterP, (state) => {
      state.meterP.loading = true;
      state.meterP.error = false;
    })
    .addCase(fetchMeterPSuceess, (state, action: any) => {
      const meterP = processData(action.payload.meterP, null);

      state.meterP = { ...meterP, loading: false, error: false };
    })
    .addCase(fetchMeterPError, (state) => {
      state.meterP.loading = false;
      state.meterP.error = true;
    })

    // house
    .addCase(fetchHouseP, (state) => {
      state.houseP.loading = true;
      state.houseP.error = false;
    })
    .addCase(fetchHousePSuceess, (state, action: any) => {
      const houseP = processData(action.payload.data.houseP, null);

      state.houseP = { ...houseP, loading: false, error: false };
    })
    .addCase(fetchHousePError, (state) => {
      state.houseP.loading = false;
      state.houseP.error = true;
    })

    // solar
    .addCase(fetchSolarP, (state) => {
      state.solarP.loading = true;
      state.solarP.error = false;
    })
    .addCase(fetchSolarPSuceess, (state, action: any) => {
      const solarP = processData(action.payload.solarP, Math.abs);

      state.solarP = { ...solarP, loading: false, error: false };
    })
    .addCase(fetchSolarPError, (state) => {
      state.solarP.loading = false;
      state.solarP.error = true;
    })

    // solar
    .addCase(fetchConsumption, (state) => {
      state.consumption.loading = true;
      state.consumption.error = false;
    })
    .addCase(fetchConsumptionSuceess, (state, action: any) => {
      state.consumption = { data: action.payload, loading: false, error: false };
    })
    .addCase(fetchConsumptionError, (state) => {
      state.solarP.loading = false;
      state.solarP.error = true;
    })

    // battery
    .addCase(fetchBattery, (state) => {
      state.battery.loading = true;
      state.battery.error = false;
    })
    .addCase(fetchBatterySuceess, (state, action: any) => {
      const battery = processData(
        action.payload.batterySOC,
        (x: number) => (x / action.payload.batteryCapacity) * 100,
      );

      state.battery = {
        ...battery,
        loading: false,
        error: false,
      };
    })
    .addCase(fetchBatteryError, (state) => {
      state.battery.loading = false;
      state.battery.error = true;
    })
    // Predictions
    .addCase(fetchPredictions, (state) => {
      state.predictions.loading = true;
      state.predictions.error = false;
    })
    .addCase(fetchPredictionsSuceess, (state, action: any) => {
      state.predictions = {
        data: {
          meterP: processPrediction(
            action.payload.grid_prediction,
            action.payload.start_timestamp,
            action.payload.endTimestamp,
            undefined,
          ),
          houseP: processPrediction(
            action.payload.load_prediction,
            action.payload.start_timestamp,
            action.payload.endTimestamp,
            Math.abs,
          ),
          solarP: processPrediction(
            action.payload.solar_prediction,
            action.payload.start_timestamp,
            action.payload.endTimestamp,
            Math.abs,
          ),
          batterySOC: processPrediction(
            action.payload.soc_prediction,
            action.payload.start_timestamp,
            action.payload.endTimestamp,
            (x: number) => (x / action.payload.battery_capacity) * 100,
          ),
        },

        loading: false,
        error: false,
      };
    })
    .addCase(fetchPredictionsError, (state) => {
      state.predictions.loading = false;
      state.predictions.error = true;
    })

    .addCase(fetchRealTimeSuceess, (state, action: any) => {
      state.realtime.day = action.payload.data;
    })
    .addCase(fetchRealTimeError, (state) => {
      state.realtime.loading = false;
      state.realtime.error = true;
    })
    // tariff
    .addCase(fetchTariffSuceess, (state, action: any) => {
      state.tariff = action.payload;
      state.tariff.loading = false;
    })
    .addCase(fetchTariffError, (state) => {
      state.tariff.loading = false;
      state.tariff.error = true;
    })
    .addCase(setSelectedTimeStamp, (state, action) => {
      state.selectedTimestamp = action.payload;
    });
});

// Other code such as selectors can use the imported `RootState` type
export const selectActivities = (state: RootState) => state.activities;
