import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { fetchMiddleware } from './middleware/fetchMiddleware';
import { rootReducers } from './reducers';
export default function configureAppStore() {
  const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      fetchMiddleware,
    ],
  });

  // webpack MHR redux hot reload
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducers);
    });
  }

  return store;
}

export const store = configureAppStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
