import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from 'react-router-dom';
import ErrorPage from 'src/pages/exceptions/ErrorPage';

import Activity from 'src/pages/Activity/Activity';
import PasswordReset from 'src/pages/PasswordReset/PasswordReset';
import SideBar from '../layout/SideBar/SideBar';
import Login from '../pages/Login/Login';

interface IProtectedRouteProps {
  auth_status: string;
  children?: JSX.Element;
}

export const ProtectedRoute = (props: IProtectedRouteProps) => {
  const { auth_status } = props;
  if (auth_status != 'LOGGED_OUT') {
    return <Outlet />;
  }
  return <Navigate to={'/login'} replace />;
};

export const router = (status: string) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorPage />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="login/password" element={<PasswordReset />} />
        <Route element={<ProtectedRoute auth_status={status} />}>
          <Route path="dashboard" element={<SideBar />}>
            <Route path="activity" element={<Activity />} />
          </Route>
        </Route>
      </Route>,
    ),
  );
