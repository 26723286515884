import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import logo from 'src/assets/img/logo.svg';
import { selectAuth } from 'src/redux/auth';
import { useAppSelector } from 'src/redux/hooks';

import { resetPassword } from '../../redux/auth';
import styles from './PasswordReset.module.scss';
interface IPasswordResetProps {}

const PasswordReset: React.FC<IPasswordResetProps> = () => {
  const dispatch = useDispatch();
  const auth = useAppSelector(selectAuth);

  const handlePasswordRest = async (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    // @ts-ignore
    if (event.target[0].value) {
      // @ts-ignore
      dispatch(resetPassword(event.target[0].value));
    }
    form.reset();
  };

  return (
    <div className={styles.container}>
      <Form className={styles.password_form_container} onSubmit={handlePasswordRest}>
        <img className={styles.reposit_logo} src={logo} />
        <Form.Text className={styles.password_reset_text}>
          {`Enter your email address below to reset your password.
              You will be sent an email which you will need to open to continue.
              You may need to check your spam folder.`}
        </Form.Text>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email </Form.Label>
          <Form.Control required type="email" placeholder="Enter your email " />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={auth.status == 'LOGGING'}>
          Send Email
        </Button>
      </Form>
    </div>
  );
};

export default PasswordReset;
