import { createAction, createReducer } from '@reduxjs/toolkit';
import { Alert } from 'src/components/AlertManager/AlertManager';
import { accessTokenItemName, refreshTokenItemName } from 'src/config';
import type { RootState } from './store';

export type auth_status = 'IDLE' | 'LOGGED_OUT' | 'LOGGED_IN' | 'LOGGING' | 'LOGGINGOUT';
export type auth_relationship_type = 'occupancy' | 'nobill_occupancy' | undefined;
interface AuthState {
  account_type?: string;
  commissionedDate?: string;
  gridCreditsPayoutEnabled?: boolean;
  installer?: string;
  isNoBill?: boolean;
  isSunyieldRetailer?: boolean;
  operatingTimezone?: string;
  product?: string;
  retailer?: string;
  timezone?: string;
  userKey?: string;
  status: auth_status;
  relationshipType: auth_relationship_type;
}

export const loginSuccess = createAction('auth/loginSuccess');
export const loginError = createAction('auth/loginError');
export const fetchAccount = createAction('auth/fetchAccount', function prepare(response) {
  localStorage.setItem(accessTokenItemName, response.access_token);
  localStorage.setItem(refreshTokenItemName, response.refresh_token);
  return {
    payload: {},
    meta: {
      method: 'GET',
      endpoint: '/v2/account/',
      success: loginSuccess,
      error: loginError,
    },
  };
});
export const reFetchAccount = createAction('auth/reFetchAccount', function prepare() {
  Alert.show({ variant: 'info', content: 'loading user info' });
  return {
    payload: {},
    meta: {
      method: 'GET',
      endpoint: '/v2/account/',
      success: loginSuccess,
      error: loginError,
    },
  };
});
export const login = createAction('auth/login', function prepare(data: API.LoginParams) {
  localStorage.removeItem(accessTokenItemName);
  localStorage.removeItem(refreshTokenItemName);
  return {
    payload: {},
    meta: {
      method: 'POST',
      endpoint: '/v2/auth/login/',
      body: data,
      noAuthorizationHeader: true,
      success: fetchAccount,
      error: loginError,
    },
  };
});
export const logoutSuccess = createAction('auth/logoutSuccess');
export const logoutError = createAction('auth/logoutError');
export const logout = createAction('auth/logout', function prepare() {
  const refresh_token = localStorage.getItem(refreshTokenItemName);
  return {
    payload: {},
    meta: {
      endpoint: '/v2/auth/logout/',
      method: 'POST',
      body: { refresh_token: refresh_token },
      success: logoutSuccess,
      error: logoutError,
    },
  };
});
// reset Password
export const resetPasswordSuccess = createAction('auth/resetPasswordSuccess');
export const resetPasswordError = createAction('auth/resetPasswordError');
export const resetPassword = createAction('auth/resetPassword', function prepare(email: string) {
  return {
    payload: {},
    meta: {
      endpoint: '/v2/reset_password',
      method: 'POST',
      body: { email: email },
      success: resetPasswordSuccess,
      error: resetPasswordError,
    },
  };
});

const initialState = {
  status: 'IDLE',
} as AuthState;

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state) => {
      state.status = 'LOGGING';
    })
    .addCase(loginSuccess, (state, action) => {
      // @ts-ignore
      if (action.payload.relationshipType === 'nobill_occupancy') {
        localStorage.removeItem(accessTokenItemName);
        localStorage.removeItem(refreshTokenItemName);
        Alert.show({
          variant: 'warning',
          content: 'You do not have access to this application',
          timeout: 10000,
        });
        return { ...initialState, status: 'LOGGED_OUT' };
      }
      // @ts-ignore
      return { ...action.payload, status: 'LOGGED_IN' };
    })
    .addCase(loginError, () => {
      return { ...initialState, status: 'LOGGED_OUT' };
    })
    .addCase(logout, (state) => {
      state.status = 'LOGGINGOUT';
    })
    .addCase(logoutSuccess, () => {
      localStorage.removeItem(accessTokenItemName);
      localStorage.removeItem(refreshTokenItemName);
      return { ...initialState, status: 'LOGGED_OUT' };
    })
    .addCase(logoutError, (state) => {
      Alert.show({ variant: 'warning', content: 'Log out failed please try again' });
      state.status = 'LOGGED_IN';
    })

    // resetPassword
    .addCase(resetPassword, () => {
      Alert.show({
        variant: 'info',
        content: 'Sending password reset email',
        timeout: 10000,
      });
    })
    .addCase(resetPasswordSuccess, () => {
      Alert.show({
        variant: 'success',
        content:
          'We have sent a password reset email to your email address if it is registered in our system. Please check your inbox to continue.',
        timeout: 10000,
      });
    })
    .addCase(resetPasswordError, () => {
      Alert.show({
        variant: 'warning',
        content:
          'We have sent a password reset email to your email address if it is registered in our system. Please check your inbox to continue.',
        timeout: 10000,
      });
    });
});

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;
export { accessTokenItemName };
