import type { FC} from 'react';
import { useEffect } from 'react';

import moment from 'moment-timezone';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import ActivityGraph from 'src/components/ActivityGraph/ActivityGraph';
import GraphPie from 'src/components/GraphPie/GraphPie';
import { selectAuth } from 'src/redux/auth';
import { useAppSelector } from 'src/redux/hooks';
import { fetchSettlement, selectSettlement } from 'src/redux/settlement';
import {
  fetchBattery,
  fetchConsumption,
  fetchHouseP,
  fetchMeterP,
  fetchPredictions,
  fetchRealTime,
  fetchSolarP,
  fetchTariff,
  selectActivities,
  setActivityDay,
} from '../../redux/activities';
import './Activity.scss';
interface ActivityProps {}

const Activity: FC<ActivityProps> = () => {
  const auth = useAppSelector(selectAuth);
  const settlement = useAppSelector(selectSettlement);
  const activities = useAppSelector(selectActivities);
  const dispatch = useDispatch();

  const timezone = auth.timezone || moment.tz.guess();
  const { isToday } = activities.day;
  const isWeekday = moment.unix(activities.day.startTimestamp).tz(timezone).isoWeekday() < 0;
  const getTariff = () => {
    if (
      !!activities.tariff?.weekday &&
      !!activities.tariff?.weekend &&
      activities.tariff?.weekday.length > 0 &&
      activities.tariff?.weekend.length > 0
    ) {
      return isWeekday ? activities.tariff?.weekday[0]?.rate : activities.tariff?.weekend[0]?.rate;
    }
    return undefined;
  };
  const tariff = getTariff();

  useEffect(() => {
    dispatch(fetchTariff(auth.userKey));
  }, []);

  // set startTimestamp when activity page first load
  useEffect(() => {
    const day = moment().tz(timezone);
    if (!activities.day.startTimestamp) {
      const startTimestamp = day.startOf('day').unix();
      const endTimestamp = day.endOf('day').unix();
      dispatch(
        setActivityDay({
          startTimestamp: startTimestamp,
          endTimestamp: endTimestamp,
          isToday: true,
        }),
      );
    }
  }, []);

  // fetch data when selected date change
  useEffect(() => {
    if (activities.day.startTimestamp && activities.day.endTimestamp) {
      dispatch(
        fetchHouseP(auth.userKey, activities.day.startTimestamp, activities.day.endTimestamp),
      );
      dispatch(
        fetchMeterP(
          auth.timezone,
          auth.userKey,
          activities.day.startTimestamp,
          activities.day.endTimestamp,
        ),
      );
      dispatch(
        fetchSolarP(auth.userKey, activities.day.startTimestamp, activities.day.endTimestamp),
      );
      dispatch(
        fetchBattery(auth.userKey, activities.day.startTimestamp, activities.day.endTimestamp),
      );

      // fetch prediction
      if (activities.day.isToday) {
        dispatch(fetchRealTime(auth.userKey, 'day', false, undefined));
        dispatch(fetchConsumption(auth.userKey));
        dispatch(
          fetchPredictions(
            auth.timezone,
            auth.userKey,
            activities.day.startTimestamp,
            activities.day.endTimestamp,
          ),
        );
      } else {
        // fetch selected date summary
        dispatch(
          fetchRealTime(
            auth.userKey,
            'day',
            false,
            moment.unix(activities.day.startTimestamp).tz(timezone).format('YYYY-MM-DD'),
          ),
        );
      }
    }
  }, [activities.day]);

  // fetch current month settlement when activity page first load
  useEffect(() => {
    const monthStartDate = moment
      .unix(activities.day.startTimestamp)
      .tz(timezone)
      .startOf('month')
      .format('YYYY-MM-DD');

    if (!settlement.data[`${monthStartDate}`]) {
      const monthEndDate = moment
        .unix(activities.day.startTimestamp)
        .tz(timezone)
        .startOf('month')
        .add(1, 'months')
        .format('YYYY-MM-DD');
      dispatch(
        fetchSettlement({
          userKey: auth.userKey!,
          startDate: monthStartDate,
          endDate: monthEndDate,
        }),
      );
    }
  }, []);

  return (
    <div className="Activity">
      <div className="activity-title-container">
        <h1>
          {isToday
            ? 'Today'
            : moment(activities.day.startTimestamp * 1000)
                .tz(timezone)
                .format('ddd D MMMM YYYY')}
        </h1>
        <p>{timezone}</p>
      </div>

      <hr />
      <div className="activity-graphs-container">
        {/* Estimated Grid Import Export */}
        <ActivityGraph
          type={'meter'}
          startTimestamp={activities.day.startTimestamp}
          endTimestamp={activities.day.endTimestamp}
          data={activities.meterP}
          predictionData={activities.predictions.data.meterP}
          isToday={isToday}
          tariff={tariff}
        />

        {/* Self Consumption */}

        <GraphPie
          isToday={isToday}
          date={moment.unix(activities.day.startTimestamp).tz(timezone).format('YYYY-MM-DD')}
        />

        {/* Estimated House Usage */}
        <ActivityGraph
          type={'house'}
          startTimestamp={activities.day.startTimestamp}
          endTimestamp={activities.day.endTimestamp}
          data={activities.houseP}
          predictionData={activities.predictions.data.houseP}
          isToday={isToday}
          tariff={tariff}
        />

        {/* Total solar generation */}
        <ActivityGraph
          type={'solar'}
          startTimestamp={activities.day.startTimestamp}
          endTimestamp={activities.day.endTimestamp}
          data={activities.solarP}
          predictionData={activities.predictions.data.solarP}
          isToday={isToday}
          tariff={tariff}
        />
        {/* battery state of charge */}
        <ActivityGraph
          type={'battery'}
          startTimestamp={activities.day.startTimestamp}
          endTimestamp={activities.day.endTimestamp}
          data={activities.battery}
          predictionData={activities.predictions.data.batterySOC}
          isToday={isToday}
          tariff={tariff}
        />
      </div>
    </div>
  );
};

export default Activity;
