import type { FC} from 'react';
import { useEffect, useState } from 'react';
import { selectActivities } from 'src/redux/activities';
import { useAppSelector, useElementSize } from 'src/redux/hooks';
import { selectSettlement } from 'src/redux/settlement';
import { VictoryLabel, VictoryPie } from 'victory';
import './GraphPie.scss';
interface GraphPieProps {
  isToday: boolean;
  date: string;
}

const GraphPie: FC<GraphPieProps> = (props) => {
  const settlement = useAppSelector(selectSettlement);
  const activities = useAppSelector(selectActivities);
  const [pieData, setPieData] = useState({
    data: [
      { x: 1, y: 0 },
      { x: 2, y: 1 },
    ],
    spent: '0',
    self_consumption: '',
  });

  const [graphSize, graphRef] = useElementSize();
  useEffect(() => {
    const currentSettlement = settlement.data[`${props.date.slice(0, -2) + '01'}`]?.find(
      (item: any) => item?.settlement_date === props.date,
    );
    if (!currentSettlement) {
      return;
    }
    setPieData({
      data: [
        { x: 1, y: currentSettlement?.self_consumption },
        { x: 2, y: 1 - currentSettlement?.self_consumption },
      ],
      spent: (-currentSettlement.cost_import - currentSettlement.cost_export).toFixed(2),
      self_consumption: (+currentSettlement.self_consumption * 100).toFixed(2),
    });
  }, [settlement, props.date]);

  return (
    //
    <div className="activity-graph-container">
      <div className="stats-container">
        <div className="stat">
          <div className="value">{`${
            props.isToday
              ? activities.consumption.data?.solar_source?.value || ''
              : pieData.self_consumption
          }%`}</div>
          <div className="label">Self Consumption</div>
        </div>
      </div>
      <div className="GraphPie" ref={graphRef}>
        <svg
          width={graphSize?.width ? graphSize.width - 20 : 160}
          height={graphSize?.height ? graphSize.height - 20 : 160}
          viewBox="0 0 160 160"
        >
          <VictoryPie
            standalone={false}
            colorScale={['#45a9d3', 'lightgrey']}
            width={160}
            height={160}
            data={pieData.data}
            radius={80}
            innerRadius={70}
            labelRadius={180}
            style={{ labels: { fontSize: 20, fill: 'white' } }}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 14 }}
            x={80}
            y={60}
            text={`$${Math.abs(+pieData.spent)}`}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 14, fill: '#3ca9d7' }}
            x={80}
            y={80}
            text={+pieData.spent < 0 ? 'earned' : 'spent'}
          />
          {props.isToday && (
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 14, fill: '#3ca9d7' }}
              x={80}
              y={100}
              text="so far today"
            />
          )}
        </svg>
      </div>
      <hr className="activity-hr" />
    </div>
  );
};

export default GraphPie;
