import moment from 'moment-timezone';

import type { FC } from 'react';
import { useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import type { Value } from 'react-calendar/dist/cjs/shared/types';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { setActivityDay } from 'src/redux/activities';
import { selectAuth } from 'src/redux/auth';
import { useAppSelector } from 'src/redux/hooks';
import { fetchSettlement, selectSettlement } from 'src/redux/settlement';
import { Alert } from '../AlertManager/AlertManager';
import CalendarDayContent from '../CalendarDayContent/CalendarDayContent';
import './HistoryCalendar.scss';

/**
 * react calendar can not set timezone it use defualt local timezone which it obtains from the browser (via JS Date object).
 * so we need to format the date to YYYY-MM-DD date string
 * @param {Date} date
 * @returns {date string in YYYY-MM-DD}
 */
const convertCalendarDate = (date: Date): string => {
  return date.toLocaleString('sv', { timeZoneName: 'short' }).slice(0, 10);
};

interface HistoryCalendarProps {}

const HistoryCalendar: FC<HistoryCalendarProps> = () => {
  const auth = useAppSelector(selectAuth);
  const settlement = useAppSelector(selectSettlement);
  const dispatch = useDispatch();
  const timezone = auth.timezone || moment.tz.guess();

  /**
   * React calendar pie chart component
   *
   * @param {Date} activeStartDate
   * @param {Date} date
   * @returns {<CalendarDayContent>}
   */
  const handleTtileContent = (activeStartDate: Date, date: Date) => {
    const contentDateString = convertCalendarDate(date);
    const activeStartDateString = convertCalendarDate(activeStartDate);

    if (settlement.data[`${activeStartDateString}`]) {
      const contentSettlement = settlement.data[`${activeStartDateString}`]?.find(
        (item: any) => item?.settlement_date === contentDateString,
      );
      if (contentSettlement) {
        const data = [
          { x: '1', y: contentSettlement?.self_consumption },
          { x: '2', y: 1 - contentSettlement?.self_consumption },
        ];
        return (
          <CalendarDayContent
            data={data}
            label={contentSettlement.cost_export + contentSettlement.cost_import}
          />
        );
      }
    }
    const data = [
      { x: '1', y: 0 },
      { x: '2', y: 1 },
    ];
    return <CalendarDayContent data={data} />;
  };
  const handleFormatMonthYear = (date: any) => {
    // set date to activeStartDate
    date.setDate(1);
    const activeStartDateString = convertCalendarDate(date);

    const titleString = moment(activeStartDateString, 'YYYY-MM-DD').format('MMM YYYY');

    if (settlement.data[`${activeStartDateString}`]) {
      const monthlyCost = settlement.data[`${activeStartDateString}`]
        .map((cost: any) => cost.cost_import + cost.cost_export)
        .reduce((prev: number, curr: number) => prev + curr, 0);
      return titleString + ' $' + monthlyCost.toFixed(2);
    }
    return titleString;
  };

  const handleActiveStartDateChange = async (activeStartDate: Date | null) => {
    if (!activeStartDate) {
      Alert.show({
        variant: 'info',
        content: 'Active start date not available',
        timeout: 1000,
      });
      return;
    }
    const activeStartDateString = convertCalendarDate(activeStartDate);
    const activeEndDateString = moment(activeStartDateString, 'YYYY-MM-DD')
      .endOf('month')
      .format('YYYY-MM-DD');

    // check whether  data exist in redux settlement
    if (!settlement.data[`${activeStartDateString}`]) {
      dispatch(
        fetchSettlement({
          userKey: auth.userKey!,
          startDate: activeStartDateString,
          endDate: activeEndDateString,
        }),
      );
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(1);

    handleActiveStartDateChange(currentDate);
  }, []);

  const handleDateChange = (date: Value) => {
    if (date instanceof Date) {
      const selectedDateString = convertCalendarDate(date);
      const selectedDay = moment(selectedDateString, 'YYYY-MM-DD').tz(timezone).startOf('day');
      const isToday = selectedDateString === convertCalendarDate(new Date());

      const startTimestamp = selectedDay.unix();
      const endTimestamp = selectedDay.endOf('day').unix();

      dispatch(
        setActivityDay({
          startTimestamp: startTimestamp,
          endTimestamp: endTimestamp,
          isToday: isToday,
        }),
      );
    }
  };

  return (
    <div className="History">
      <Calendar
        maxDate={new Date()}
        onChange={(date) => {
          handleDateChange(date);
        }}
        allowPartialRange={false}
        showNeighboringMonth={false}
        onActiveStartDateChange={({ activeStartDate }) => {
          handleActiveStartDateChange(activeStartDate);
        }}
        tileContent={({ activeStartDate, date }) => handleTtileContent(activeStartDate, date)}
        formatMonthYear={(locale, date) => handleFormatMonthYear(date)}
      />
    </div>
  );
};

export default HistoryCalendar;
