import type { FC } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import icon_batteryempty from 'src/assets/img/ico_batteryempty.svg';
import icon_batteryfull from 'src/assets/img/ico_batteryfull.svg';
import icon_gridcredit from 'src/assets/img/ico_gridcredit.svg';
import icon_highusage from 'src/assets/img/ico_highusage.svg';
import icon_solarpill from 'src/assets/img/ico_solarspill.svg';
import icon_weather from 'src/assets/img/ico_weather.svg';

import icon_systemoffline from 'src/assets/img/ico_systemoffline.svg';

import icon_gridoutage from 'src/assets/img/ico_gridoutage.svg';
import icon_message from 'src/assets/img/ico_message.svg';
import icon_payout from 'src/assets/img/ico_payout.svg';

import icon_storm from 'src/assets/img/ico_storm.svg';
import './Message.scss';

const MESSAGE_TYPE_ICON_MAP = {
  GRID_CREDITS: icon_gridcredit,
  GRIDCREDITS: icon_gridcredit,
  WEATHER_FORECAST: icon_weather,
  BATTERY_EMPTY: icon_batteryempty,
  BATTERY_FULL: icon_batteryfull,
  HIGH_USAGE: icon_highusage,
  SOLAR_SPILL: icon_solarpill,
  SYSTEM_OFFLINE: icon_systemoffline,
  STORM_APPROACHING: icon_storm,
  GRID_OUTAGE: icon_gridoutage,
  PAYOUTS: icon_payout,
  DEFAULT: icon_message,
};

interface MessageProps {
  type:
    | 'GRID_CREDITS'
    | 'GRIDCREDITS'
    | 'WEATHER_FORECAST'
    | 'BATTERY_EMPTY'
    | 'BATTERY_FULL'
    | 'HIGH_USAGE'
    | 'SOLAR_SPILL'
    | 'SYSTEM_OFFLINE'
    | 'STORM_APPROACHING'
    | 'GRID_OUTAGE'
    | 'PAYOUTS'
    | 'DEFAULT';
  title: string;
  body: string;
  created_at: number;
}

const Message: FC<MessageProps> = (message) => {
  const date = new Date(message.created_at * 1000);
  const [modalShow, setModalShow] = useState<boolean>(false);
  return (
    <>
      <div className="Message" onClick={() => setModalShow(true)}>
        <div className="message-date">{`${
          date.toString().split(' ')[0]
        }  ${date.toLocaleDateString()}`}</div>
        <div className="message-card">
          <img src={MESSAGE_TYPE_ICON_MAP[message.type] || MESSAGE_TYPE_ICON_MAP.DEFAULT} />
          <div className="message-content">
            <h6>{message.title}</h6>
            <div className="message-body">{message.body}</div>
          </div>
        </div>
        <hr style={{ marginTop: 6 }} />
      </div>

      <Modal className="message-modal" centered show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{message.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="message-modal-body">
          <img src={MESSAGE_TYPE_ICON_MAP[message.type] || MESSAGE_TYPE_ICON_MAP.DEFAULT} />
          <p>{message.body}</p>
          <div className="message-modal-time">{date.toLocaleString()}</div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Message;
