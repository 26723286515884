import { useState } from 'react';
import { Button, Modal, Offcanvas, Spinner } from 'react-bootstrap';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import {
  MdLogout,
  MdOutlineDataExploration,
  MdOutlineHistory,
  MdOutlineMessage,
} from 'react-icons/md';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { Outlet, useNavigation } from 'react-router-dom';
import logo from 'src/assets/img/logo.svg';
import HistoryCalendar from 'src/components/HistoryCalendar/HistoryCalendar';
import { resetActivityDay, selectActivities } from 'src/redux/activities';
import { logout, selectAuth } from 'src/redux/auth';
import { useAppSelector } from 'src/redux/hooks';

import Messages from 'src/pages/Messages/Messages';
import './SideBar.scss';
type OffCanvasProps = {
  show: boolean;
  type: 'Calendar' | 'Messages';
};
const SideBar: React.FC = ({}) => {
  const navigation = useNavigation();
  const auth = useAppSelector(selectAuth);
  const activities = useAppSelector(selectActivities);
  const dispatch = useDispatch();

  const theme = 'light';

  const [offCanvas, setOffCanvas] = useState<OffCanvasProps>({ show: false, type: 'Calendar' });
  const [logoutModalShow, setlogoutModalShow] = useState<boolean>(false);

  const handleClose = (type: OffCanvasProps['type']) => {
    setOffCanvas({ show: false, type: type });
  };
  const handleShow = (type: OffCanvasProps['type']) => {
    setOffCanvas({ show: true, type: type });
  };
  const handleClickToday = () => {
    if (!activities.day.isToday) {
      dispatch(resetActivityDay());
    }
  };

  return (
    <div className="layout-container" style={{ display: 'flex', width: '100%', height: '100%' }}>
      <SidebarMenu variant={theme} bg={theme} expand="xs">
        <SidebarMenu.Collapse>
          <SidebarMenu.Header>
            <img className="sidebar-logo" src={logo} />
            <SidebarMenu.Toggle />
          </SidebarMenu.Header>
          <SidebarMenu.Body>
            <SidebarMenu.Nav>
              <SidebarMenu.Nav.Link eventKey="today" onClick={() => handleClickToday()}>
                <SidebarMenu.Nav.Icon>
                  <MdOutlineDataExploration />
                </SidebarMenu.Nav.Icon>
                <SidebarMenu.Nav.Title>Today</SidebarMenu.Nav.Title>
              </SidebarMenu.Nav.Link>

              <SidebarMenu.Nav.Link eventKey="history" onClick={() => handleShow('Calendar')}>
                <SidebarMenu.Nav.Icon>
                  <MdOutlineHistory />
                </SidebarMenu.Nav.Icon>
                <SidebarMenu.Nav.Title>History</SidebarMenu.Nav.Title>
              </SidebarMenu.Nav.Link>

              <SidebarMenu.Nav.Link eventKey="message" onClick={() => handleShow('Messages')}>
                <SidebarMenu.Nav.Icon>
                  <MdOutlineMessage />
                </SidebarMenu.Nav.Icon>
                <SidebarMenu.Nav.Title>Message</SidebarMenu.Nav.Title>
              </SidebarMenu.Nav.Link>

              <SidebarMenu.Nav.Link className="logout" onClick={() => setlogoutModalShow(true)}>
                <SidebarMenu.Nav.Icon>
                  <MdLogout />
                </SidebarMenu.Nav.Icon>
                <SidebarMenu.Nav.Title>Logout</SidebarMenu.Nav.Title>
              </SidebarMenu.Nav.Link>
            </SidebarMenu.Nav>
          </SidebarMenu.Body>
        </SidebarMenu.Collapse>
      </SidebarMenu>

      <Offcanvas show={offCanvas.show} onHide={handleClose} className="offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{offCanvas.type}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {offCanvas.type == 'Calendar' && <HistoryCalendar />}
          {offCanvas.type == 'Messages' && <Messages />}
        </Offcanvas.Body>
      </Offcanvas>
      {/* <div  */}
      <Modal centered show={logoutModalShow} onHide={() => setlogoutModalShow(false)}>
        {/*  */}
        <div className="logout-modal">
          {auth.status == 'LOGGINGOUT' ? (
            <>
              <Spinner style={{ width: '3rem', height: '3rem' }} variant="primary" />
              <p>Logging out</p>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Log Out</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>Are you sure you want to log out?</p>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={() => setlogoutModalShow(false)}>
                  No
                </Button>

                <Button variant="primary" onClick={() => dispatch(logout())}>
                  Yes
                </Button>
              </Modal.Footer>
            </>
          )}
        </div>
      </Modal>
      {/* </div> */}

      <div id="detail" className={navigation.state === 'loading' ? 'loading' : ''}>
        <Outlet />
      </div>
    </div>
  );
};
export default SideBar;
